import React, { useState, useEffect } from "react";
import logo from "../../assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import eng from "../../assets/images/eng.gif";
import nep from "../../assets/images/nep.gif";
// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";

export default function Header() {
  const [toggle, setToggle] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const toggleOpen = () => {
    setToggle(!toggle);
  };
  const toggleMenu = () => {
    setMenuToggle(!menuToggle);
  };
  useEffect(() => {
    setToggle(false);
  }, [i18next.language]);
  return (
    <nav className="sticky shadow-md top-0 shadow-navbar-custom transition-all duration-100 ease-linear delay-75 sticky top-0 z-[1001] bg-white border-gray-200 px-4 py-4">
      <Drawer
        open={menuToggle}
        onClose={toggleMenu}
        direction="right"
        className="relative"
      >
        <ul className="ml-2 md:flex flex-row p-4 mt-4 ">
          <div className="border-b border-gray-200">
            <li className="mb-4">
              <div className="flex relative">
                <button
                  onClick={toggleOpen}
                  id="dropdownDividerButton"
                  data-dropdown-toggle="dropdownDivider"
                  className="text-black w-max bg-white rounded-lg text-base text-center inline-flex items-center"
                  type="button"
                >
                  {i18next.language === "en" ? (
                    <div className="flex items-center text-base">
                      <img className="w-5 mr-2 h-5" src={eng} />
                      English
                    </div>
                  ) : (
                    <div className="flex items-center text-base">
                      <img className="w-5 mr-2 h-5" src={nep} />
                      नेपाली
                    </div>
                  )}
                  <svg
                    className="ml-2 w-4 h-4"
                    aria-hidden="true"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                <div
                  className={
                    toggle
                      ? "z-10 w-44 bg-white left-[-15px] top-[36px] absolute rounded divide-y divide-gray-100 shadow"
                      : "hidden"
                  }
                >
                  <ul
                    className="p-4 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDividerButton"
                  >
                    {i18next.language === "np" && (
                      <li>
                        <a
                          onClick={() => i18next.changeLanguage("en")}
                          className={
                            "flex text-black items-center cursor-pointer"
                          }
                        >
                          <img className="w-5 mr-2 h-5" src={eng} />
                          English
                        </a>
                      </li>
                    )}
                    {i18next.language === "en" && (
                      <li>
                        <a
                          onClick={() => i18next.changeLanguage("np")}
                          className="flex text-black items-center cursor-pointer"
                        >
                          <img className="w-5 mr-2 h-5" src={nep} />
                          नेपाली
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </li>
          </div>
          <div className="mt-2">
            <li className="mb-2">
              <Link
                to="/about"
                className={
                  location.pathname === "/about"
                    ? "whitespace-nowrap text-base font-medium text-[#963aee] hover:text-[#963aee]"
                    : "whitespace-nowrap text-base font-medium text-[#0F2137] hover:text-[#963aee]"
                }
                aria-current="page"
              >
                {t("menu_1")}
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/contact"
                className={
                  location.pathname === "/contact"
                    ? "whitespace-nowrap text-base font-medium text-[#963aee] hover:text-[#963aee]"
                    : "whitespace-nowrap text-base font-medium text-[#0F2137] hover:text-[#963aee]"
                }
              >
                {t("menu_2")}
              </Link>
            </li>
          </div>
          <li className="absolute bottom-[2rem] left-[10px]">
            <Link
              to="/download"
              className="ml-4 hover:transition-all inline-flex whitespace-nowrap rounded-md border border-transparent bg-[#963aee] px-4 py-2 text-base font-medium text-white shadow-sm hover:shadow-lg"
            >
              {t("download_app")}
            </Link>
          </li>
        </ul>
      </Drawer>
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <div className="flex">
          <Link to="/" className="flex items-center" href="/">
            <img src={logo} className="mr-1 w-28" />
          </Link>
          <ul className="hidden ml-7 md:flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
            <li>
              <Link
                to="/about"
                className={
                  location.pathname === "/about"
                    ? "whitespace-nowrap text-base font-medium text-[#963aee] hover:text-[#963aee]"
                    : "whitespace-nowrap text-base font-medium text-[#0F2137] hover:text-[#963aee]"
                }
                aria-current="page"
              >
                {t("menu_1")}
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={
                  location.pathname === "/contact"
                    ? "whitespace-nowrap text-base font-medium text-[#963aee] hover:text-[#963aee]"
                    : "whitespace-nowrap text-base font-medium text-[#0F2137] hover:text-[#963aee]"
                }
              >
                {t("menu_2")}
              </Link>
            </li>
            {/* <li>
              <Link
                to="/become_rider"
                className={
                  location.pathname === "/blog"
                    ? "whitespace-nowrap text-base font-medium text-[#963aee] hover:text-[#963aee]"
                    : "whitespace-nowrap text-base font-medium text-[#0F2137] hover:text-[#963aee]"
                }
              >
                {t("menu_3")}
              </Link>
            </li> */}
          </ul>
        </div>
        <button
          onClick={toggleMenu}
          data-collapse-toggle="mobile-menu"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
          <svg
            className="hidden w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        <div className="w-full md:block md:w-auto hidden" id="mobile-menu">
          <div className="hidden flex items-center justify-end md:flex md:flex-1 lg:w-0">
            <div className="flex relative">
              <button
                onClick={toggleOpen}
                id="dropdownDividerButton"
                data-dropdown-toggle="dropdownDivider"
                className="text-black w-max bg-white rounded-lg text-base text-center inline-flex items-center"
                type="button"
              >
                {i18next.language === "en" ? (
                  <div className="flex items-center text-base">
                    <img className="w-5 mr-2 h-5" src={eng} />
                    English
                  </div>
                ) : (
                  <div className="flex items-center text-base">
                    <img className="w-5 mr-2 h-5" src={nep} />
                    नेपाली
                  </div>
                )}
                <svg
                  className="ml-2 w-4 h-4"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <div
                className={
                  toggle
                    ? "z-10 w-44 bg-white left-[-15px] top-[36px] absolute rounded divide-y divide-gray-100 shadow"
                    : "hidden"
                }
              >
                <ul
                  className="p-4 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDividerButton"
                >
                  {i18next.language === "np" && (
                    <li>
                      <a
                        onClick={() => i18next.changeLanguage("en")}
                        className={
                          "flex text-black items-center cursor-pointer"
                        }
                      >
                        <img className="w-5 mr-2 h-5" src={eng} />
                        English
                      </a>
                    </li>
                  )}
                  {i18next.language === "en" && (
                    <li>
                      <a
                        onClick={() => i18next.changeLanguage("np")}
                        className="flex text-black items-center cursor-pointer"
                      >
                        <img className="w-5 mr-2 h-5" src={nep} />
                        नेपाली
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <Link
              to="/download"
              className="ml-4 hover:transition-all	 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#963aee] px-4 py-2 text-base font-medium text-white shadow-sm hover:shadow-lg"
            >
              {t("download_app")}
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}
