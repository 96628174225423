import React from "react";
import banner from "../../assets/images/mainbanner.webp";
import { useTranslation } from "react-i18next";
import "animate.css";

export default function Banner() {
  const { t } = useTranslation();
  return (
    <>
      <section
        className="bg-local"
        style={{
          backgroundColor: "#DAEAF6",
        }}
      >
        <div className="container-fluid grid mx-auto lg:grid-cols-12 p-12 lg:p-0">
          <div className="mr-auto md:pl-14 place-self-center lg:col-span-5 wow animate__animated animate__jackInTheBox">
            <h1
              title={t("banner_title")}
              className="max-w-2xl mb-4 text-3xl leading-[3.25rem] font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl text-[#0F2137] md:leading-[3.25rem] xl:leading-[3.25rem]"
            >
              {t("banner_title")}
            </h1>
            <p
              title={t("banner_subtitle")}
              className="max-w-2xl mb-6 font-light text-[#0F2137] lg:mb-8 md:text-md lg:text-lg"
            >
              {t("banner_subtitle")}
            </p>
            <button className="transform mr-4 mb-2 rounded-md bg-[#963aee] px-5 py-3 font-medium text-dark transition-colors hover:shadow-lg inline-flex items-center">
              <svg
                className="w-4 h-4 mr-2 fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
              </svg>

              <a
                target="_blank"
                className="text-white"
                href="https://sajilo.app.link/VL1dmdxErrb"
              >
                {t("download_app")}
              </a>
            </button>
            <button className="transform mr-4 rounded-md bg-white px-5 py-3 font-medium text-dark transition-colors hover:shadow-lg inline-flex items-center">
              <a target="_blank" href="https://sajilo.app.link/VL1dmdxErrb">
                {t("become_rider")}
              </a>
            </button>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-7 lg:flex wow animate__animated animate__slideInRight animate__faster">
            <img
              src={banner}
              className="relative "
              alt="Take a hassle free ride with Sajilo - Ride Sharing App"
            />
          </div>
        </div>
      </section>
    </>
  );
}
