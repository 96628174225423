import React from "react";
import { Helmet } from "react-helmet";

export default function Terms() {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Sajilo - Ride Sharing</title>
        <link rel="canonical" href="http://sajiloridesharing.com/about" />
      </Helmet>
      <section className="container  px-4 mx-auto py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-medium leading-10 tracking-tight text-[#0F2137]">
            Terms and Conditions
          </h1>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            Contractual Relationship
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            These "Terms & Conditions" administer the entrance or use by you, a
            person, from inside any nation in the realm of utilizations, sites,
            substance, items, and administrations (the "Administrations"). It
            would be ideal if you read these terms and conditions carefully
            before accessing the services. Your entrance and utilization of the
            Services comprises your consent to be bound by these Terms &
            Conditions, which builds up a legally binding connection amidst you
            and Sajilo. In the event that you don't consent to these Terms &
            Conditions, you may not access or utilize the Services. Sajilo may
            quickly end services as for you, or by and large stop offering or
            deny access to the Services or any segment thereof, whenever for any
            reason. Supplemental terms may apply to specific services, during a
            specific occasion, movement or advancement, and such supplemental
            terms will be unveiled to you regarding the material services.
            Supplemental terms notwithstanding, will be esteemed a piece of, the
            Terms & Conditions for the motivations behind the material services.
            Supplemental terms will beat these Terms & Conditions in case of a
            contention regarding the pertinent services. Sajilo may correct the
            Terms & Conditions identified with the services every once in a
            while. Alterations will be done upon Sajilo posting of updated Terms
            & Conditions at this area or the changed arrangements or
            supplemental terms on the relevant Service. Your proceeding with
            access or utilization of the services after such presenting
            establishes your assent to be bound by the Terms & Conditions, as
            altered. Sajilo may give to a cases processor or a safety net
            provider any fundamental data (counting your contact data) if there
            is a dissension, debate or strife, which may incorporate a mishap,
            including you and a Third Party Provider and such data or
            information is important to determine the grumbling, question or
            struggle.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">License.</p>
          <p className="mt-2 text-base text-strong text-gray-600">
            Subject to your consistency with these Terms & Conditions, Sajilo
            awards you a constrained, non-elite, non-sublicensable, revocable,
            non-transferrable permit to: (I) access and utilize the Applications
            on your own gadget exclusively regarding your utilization of the
            Services; and (ii) access and utilize any substance, data and
            related materials that might be made accessible through the
            Services, for each situation exclusively for your own, noncommercial
            use. Any rights not explicitly allowed in this are held by Sajilo
            licensors.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">Restrictions.</p>
          <p className="mt-2 text-base text-strong text-gray-600">
            You may not: (I) evacuate any copyright, trademark or other
            exclusive notification from any bit of the Services; (ii) replicate,
            change, get ready subsidiary works dependent on, circulate, permit,
            rent, move, exchange, exchange, freely show, openly perform,
            transmit, stream, communicate or generally misuse the Services aside
            from as explicitly allowed by Sajilo; (iii) decompile, figure out or
            dismantle the Services with the exception of as might be allowed by
            material law; (iv) connection to, mirror or edge any bit of the
            services; (v) cause or dispatch any projects or contents to scrape,
            ordering, looking over, or generally information mining any part of
            the Services or unduly loading or frustrating the task as well as
            usefulness of any part of the Services; or (vi) endeavor to increase
            unapproved access to or disable any part of the Services or its
            related frameworks or systems.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">Ownership.</p>
          <p className="mt-2 text-base text-strong text-gray-600">
            The Services and rights will remain Sajilo property licensors.
            Neither these Terms & Conditions, nor your utilization of the
            Services pass on or concede to you any rights: (I) in or identified
            with the Services aside from the constrained permit allowed above;
            or (ii) to utilize or reference in any way Sajilo organization
            names, logos, item and administration names, trademarks or
            administrations marks or those of Sajilo licensors.
          </p>
        </div>
        <div className="mb-8">
          <h1 className="text-3xl font-medium leading-10 tracking-tight text-[#0F2137]">
            Your Use of the Services
          </h1>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">User Accounts.</p>
          <p className="mt-2 text-base text-strong text-gray-600">
            To utilize most parts of the Services, you should enroll for and
            keep up a functioning individual client Services ("Account"). You
            should be 18+ years old, to acquire an Account. Record enlistment
            expects you to submit to Sajilo individual data, for example, your
            name, address, cell phone number and age, and additionally no less
            than one substantial installment technique (either a charge card or
            acknowledged installment accomplice). You consent to keep up exact,
            finish, and up and coming data in your Account. Your inability to
            keep up exact, finish, and up and coming Account data, including
            having an invalid or lapsed installment strategy on document, may
            result in your powerlessness to access and utilize the Services or
            Sajilo end these Terms & Conditions with you. You are in charge of
            all activity happens under your Account, and you consent to keep up
            the security and mystery of your Account username and secret key
            consistently.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            User Requirements and Conduct.
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            The services aren’t accessible for use by people younger than 18.
            You may not approve outsiders to utilize your Account, and you may
            not permit people younger than 18 to get transportation or
            coordination administrations from Third Party Providers. You may not
            relegate or generally exchange your Account to some other individual
            or substance. You consent to follow every single pertinent law when
            utilizing the services, and you may just utilize the services for
            legal purposes. You won't, in your utilization of the Services,
            cause disturbance, irritation, burden, or property harm, regardless
            of whether to the Third Party Provider or some other gathering. In
            specific occurrences you might be solicited to give confirmation
            from identity to access or utilize the Services, and you concur that
            you might be denied access to or utilization of the Services in the
            event that you decline to give evidence of personality.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            Promotional Codes.
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            Sajilo offers limited time codes that might be reclaimed for Account
            credit, or different highlights or advantages identified with the
            Services as well as a Third Party Provider's administrations,
            subject to any extra terms that Sajilo sets up on a for every
            special code premise ("Promo Codes"). You concur that Promo Codes:
            (I) must be utilized for the target group and reason, and in a legal
            way; (ii) may not be copied, sold or moved in any way, or made
            accessible to the overall population (regardless of whether
            presented on an open frame or something else), except if explicitly
            allowed by Sajilo; (iii) might be invalidated by Sajilo whenever for
            any reason without risk; (iv) may just be utilized as per the
            explicit terms that Sajilo sets up for such Promo Code; (v) are not
            substantial for money; (vi) may lapse preceding your utilization.
            Sajilo maintains whatever authority is needed to retain or deduct
            credits or different highlights or advantages acquired using Promo
            Codes by you or some other client if Sajilo decides or trusts that
            the utilization or reclamation of the Promo Code was in blunder,
            deceitful, unlawful, or disregarding the pertinent Promo Code terms
            or these Terms & Conditions.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            User Requirements and Conduct.
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            The services aren’t accessible for use by people younger than 18.
            You may not approve outsiders to utilize your Account, and you may
            not permit people younger than 18 to get transportation or
            coordination administrations from Third Party Providers. You may not
            relegate or generally exchange your Account to some other individual
            or substance. You consent to follow every single pertinent law when
            utilizing the services, and you may just utilize the services for
            legal purposes. You won't, in your utilization of the Services,
            cause disturbance, irritation, burden, or property harm, regardless
            of whether to the Third Party Provider or some other gathering. In
            specific occurrences you might be solicited to give confirmation
            from identity to access or utilize the Services, and you concur that
            you might be denied access to or utilization of the Services in the
            event that you decline to give evidence of personality.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            User Provided Content.
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            Sajilo may allow you now and again to submit, transfer, distribute
            or generally make accessible to the Services literary, sound, or
            potentially visual substance and data, including critique and
            criticism identified with the Services, commencement of help
            solicitations, and accommodation of passages for rivalries and
            advancements ("User Content"). Any User Content given by you remains
            your property. In any case, by giving User Content to Sajilo, you
            give an around the world, unending, permanent, transferrable,
            eminence free permit, with the privilege to sublicense, to utilize,
            duplicate, adjust, make subordinate works of, appropriate, openly
            show, freely perform, and generally misuse in any way such User
            Content in all arrangements and dissemination channels currently
            known or in the future contrived (incorporating into association
            with the Services and Sajilo the same old thing and on outsider
            locales and administrations), without further notice to or assent
            from you, and without the prerequisite of installment to you or some
            other individual or element. You speak to and warrant that: (I) you
            either are the sole and select proprietor of all User Content or you
            have all rights, licenses, assets and discharges important to give
            Sajilo the permit to the User Content as put forward above; and (ii)
            neither the User Content nor your accommodation, transferring,
            distributing or generally making accessible of such User Content nor
            Sajilo utilization of the User Content as allowed in this will
            encroach, abuse or damage an outsider's protected innovation or
            exclusive rights, or privileges of exposure or security, or result
            in the infringement of any relevant law or direction. You consent to
            not give User Content that is slanderous, derogatory, contemptuous,
            savage, profane, obscene, unlawful, or generally hostile, as
            controlled by Sajilo in its sole watchfulness, regardless of whether
            such material might be secured by law. Sajilo may, however will not
            be committed to, survey, screen, or evacuate User Content, at Sajilo
            sole prudence and whenever and for any reason, without notice to
            you.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            Network Access and Devices.
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            You are in charge of getting the information arranged to be
            important to utilize the Services. Your portable system's
            information and informing rates and expenses may apply on the off
            chance that you access or utilize the Services from a remote
            empowered gadget and you will be in charge of such rates and
            charges. You are in charge of getting and refreshing perfect
            equipment or gadgets important to access and utilize the Services
            and Applications and any updates thereto. Sajilo does not ensure
            that the Services, or any segment thereof, will work on a specific
            equipment or gadgets. Also, the Services might be liable to glitches
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">Payment</p>
          <p className="mt-2 text-base text-strong text-gray-600">
            You comprehend that utilization of the Services may result in
            charges to you for the administrations or merchandise you get from a
            Third Party Provider ("Charges''). After you have gotten
            administrations or products acquired through your utilization of the
            Service, Sajilo will encourage your installment of the material
            Charges for the benefit of the Third Party Provider thusly Third
            Party Provider's constrained installment accumulation operator.
            Installment of the Charges in such way will be viewed as equivalent
            to installment made straightforwardly by you to the Third Party
            Provider. Charges will be comprehensive of relevant expenses where
            required by law. Charges paid by you are conclusive and
            non-refundable, except if generally dictated by Sajilo. You hold the
            privilege to ask for lower Charges from a Third Party Provider for
            administrations or products gotten by you from such Third Party
            Provider at the time you get such administrations or merchandise.
            Sajilo will react in like manner to any demand from a Third Party
            Provider to adjust the Charges for a specific administration or
            great. All Charges are expected quickly and installment will be
            encouraged by Sajilo utilizing the favored installment technique
            assigned in your Account, after which Sajilo will send you a receipt
            by email. In the event that your essential Account installment
            strategy is resolved to be terminated, invalid or generally not
            ready to be charged, you concur that Sajilo may, as the Third Party
            Provider's constrained installment accumulation specialist, utilize
            an optional installment technique in your Account, if accessible.
            This installment structure is planned to completely repay the Third
            Party Provider for the administrations or products given. Aside from
            as for cab transportation administrations asked for through the
            Application, Sajilo does not assign any bit of your installment as a
            tip or tip to the Third Party Provider. Any portrayal by Sajilo (on
            Sajilo site, in the Application, or in Sajilo advertising materials)
            such that tipping is "deliberate," "not required," as well as
            "included" in the installments you make for administrations or
            products gave isn't proposed to recommend that Sajilo gives any
            extra sums, past those depicted above, to the Third Party Provider.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">General</p>
          <p className="mt-2 text-base text-strong text-gray-600">
            You may not dole out or move these Terms & Conditions in entire or
            partially without Sajilo endorsement. You give your endorsement to
            Sajilo for it to appoint or move these Terms & Conditions in entire
            or to some extent, including to: (I) a backup or associate; (ii) an
            acquirer of Sajilo value, business or resources; or (iii) a
            successor by merger. On the off chance that any arrangement of these
            Terms & Conditions is held to be unlawful, invalid or unenforceable,
            in entire or to some extent, under any law, such arrangement or part
            thereof will to that degree be regarded not to frame some portion of
            these Terms & Conditions but rather the lawfulness, legitimacy and
            enforceability of alternate arrangements. These Terms & Conditions
            comprise the whole ascension and comprehension of the gatherings as
            for its topic and replaces and overrides all earlier or
            contemporaneous understandings or endeavors with respect to such
            topic.
          </p>
        </div>
      </section>
    </>
  );
}
