import React from "react";
import aboutImg from "../../assets/images/about.jpg";
import aboutImg2 from "../../assets/images/about2.jpg";
import aboutImg3 from "../../assets/images/lady.jpg";
import cabImage from "../../assets/images/cab.jpg";
import VijaySir from "../../assets/images/vijay-sir.png";
import signature from "../../assets/images/sign.png";
import book from "../../assets/images/icon-book.png";
import reach from "../../assets/images/icon-reach.png";
import pay from "../../assets/images/icon-pay.png";
import arrow from "../../assets/images/arrow.png";
import testImg from "../../assets/images/sajilo-banner.png";
import { useTranslation } from "react-i18next";

export default function About2() {
  const { t } = useTranslation();
  return (
    <section
      aria-label="Reach your destination with three easy steps"
      className="py-14 sm:py-18"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl sm:text-center">
          <h2
            title={t("title_1")}
            className="text-3xl font-medium leading-10 tracking-tight text-[#0F2137]"
          >
            {t("title_1")}
          </h2>
          <p title={t("subtitle_1")} className="mt-2 text-lg text-gray-600">
            {t("subtitle_1")}
          </p>
        </div>
        <ul
          role="list"
          className="relative mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3"
        >
          <li className="rounded-2xl p-2 md:p-8">
            <img
              src={book}
              alt="Book your ride"
              className="w-56 block m-auto wow animate__animated animate__zoomIn"
            />
            <h3 className="mt-6 text-dark mb-1 text-xl font-bold text-center">
              {t("book")}
            </h3>
            <p className="text-base mt-1 text-center text-gray-700">
              {t("book_sub")}
            </p>
            <img
              className="absolute hidden sm:hidden md:hidden xl:block top-[9rem] left-[20rem]"
              src={arrow}
            />
          </li>

          <li className="rounded-2xl p-2 md:p-8">
            <img
              src={reach}
              alt="Reach your destination"
              className="w-56 block m-auto wow animate__animated animate__zoomIn"
            />
            <h3 className="mt-6 text-dark mb-1 text-xl font-bold text-center">
              {t("reach")}
            </h3>
            <p className="text-base mt-1 text-center text-gray-700">
              {t("reach_sub")}
            </p>
            <img
              className="absolute hidden sm:hidden md:hidden xl:block top-[9rem] left-[46.5rem]"
              src={arrow}
            />
          </li>
          <li className="rounded-2xl p-2 md:p-8">
            <img
              src={pay}
              alt="Pay your bill"
              className="w-56 block m-auto wow animate__animated animate__zoomIn"
            />
            <h3 className="mt-6 text-dark mb-1 text-xl font-bold text-center">
              {t("pay")}
            </h3>
            <p className="text-base mt-1 text-center text-gray-700">
              {t("pay_sub")}
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}
