import React from "react";
import { useSearchParams } from "react-router-dom";

export default function DetailNew(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams.get("image"));
  return (
    <div className="container px-4 mx-auto">
      <img
        src={`https://buch-kunstregister.de/storage/${searchParams.get(
          "image"
        )}`}
      />
    </div>
  );
}
