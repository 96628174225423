import React from "react";
import { Link } from "react-router-dom";

export default function CarouselItem(row) {
  const { images, title } = row.row;
  return (
    <div className="bg-[#fff] p-2 m-2 relative">
      <div>
        <div className="text-[#fff] text-xs font-semibold bg-[#3f3f3f] p-2 m-2 w-max block relative top-2 rounded-md">
          <i className="fa fa-bookmark text-[#fff] text-xs mr-2"></i>1
        </div>

        <Link to={`/image?image=${images[0]?.image_path}`}>
          <img
            src={`https://buch-kunstregister.de/storage/${images[0]?.image_path}`}
            alt={"test"}
          />
        </Link>
        <div className="flex justify-end">
          <span className="bg-[#70cd73] p-2 text-[#fff]"> Sehr Get</span>
        </div>
      </div>
      <h1 className="text-center text-lg mt-2">{title}</h1>
      <div className="flex justify-center items-center mt-4">
        <a href="#" class="btn btn-interest">
          <i class="fa fa fa-book mr-1"></i>
          Kaufinteresse
        </a>
        <a
          href="https://buch-kunstregister.de/books/6?register=4078475424003594"
          className="text-[#d47b39]"
          data-original-title=""
          title=""
        >
          <i class="fa fa-long-arrow-right"></i>
        </a>
      </div>
    </div>
  );
}
