import React from "react";
import mypay from "../../assets/images/mypay.jpg";
import fourk from "../../assets/images/4k.jpg";
import searce from "../../assets/images/searce.png";
import genese from "../../assets/images/genese.png";
import paradygm from "../../assets/images/paradygm.png";
import hv from "../../assets/images/hv.png";
import { useTranslation } from "react-i18next";

export default function Partners() {
  const { t } = useTranslation();
  return (
    <section className="mb-8 border-t border-gray-200">
      <div className="py-8 lg:py-14 container mx-auto">
        <h2 className="mb-8 lg:mb-16 text-center text-3xl font-medium leading-10 tracking-tight text-[#0F2137]">
          {t("trusted_by")}
        </h2>
        <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 dark:text-gray-400">
          <a
            href="https://www.searce.com/"
            target="_blank"
            className="flex justify-center items-center wow animate__animated animate__zoomIn"
          >
            <img
              alt="Searce - Cloud Solutions and Technology Services Provider"
              className="h-16 grayscale hover:grayscale-0"
              src={searce}
            ></img>
          </a>
          <a
            href="https://www.genesesolution.com/"
            target="_blank"
            className="flex justify-center items-center wow animate__animated animate__zoomIn"
          >
            <img
              alt="Genese Solution - Cloud Consulting Company"
              className="h-16 grayscale hover:grayscale-0"
              src={genese}
            ></img>
          </a>

          <a
            href="https://mypay.com.np"
            target="_blank"
            className="flex justify-center items-center wow animate__animated animate__zoomIn"
          >
            <img
              alt="MyPay - Scan and Pay Anytime Anywhere"
              className="h-16 grayscale hover:grayscale-0"
              src={mypay}
            ></img>
          </a>
          <a
            href="#"
            target="_blank"
            className="flex justify-center items-center wow animate__animated animate__zoomIn"
          >
            <img
              alt="4K Gaming Nepal"
              className="h-16 grayscale hover:grayscale-0"
              src={fourk}
            ></img>
          </a>
          <a
            href="https://paradygm.com.np/"
            target="_blank"
            className="flex justify-center items-center wow animate__animated animate__zoomIn"
          >
            <img
              alt="Paradygm TV | Production House Based in Kathmandu"
              className="h-16 grayscale hover:grayscale-0"
              src={paradygm}
            ></img>
          </a>
          <a
            href="https://www.instagram.com/highvoltage_np"
            target="_blank"
            className="flex justify-center items-center wow animate__animated animate__zoomIn"
          >
            <img
              alt="High Voltage"
              className="h-16 grayscale hover:grayscale-0"
              src={hv}
            ></img>
          </a>
        </div>
      </div>
    </section>
  );
}
