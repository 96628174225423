import React from "react";
import About2 from "../../Components/About2";
import Banner from "../../Components/Banner";
import CTA from "../../Components/CTA";
import Download from "../../Components/Download";
import Partners from "../../Components/Partners";
import { Helmet } from "react-helmet";
import Quote from "../../Components/Quote";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Home | Sajilo - Ride Sharing</title>
        <link rel="canonical" href="http://sajiloridesharing.com/" />
      </Helmet>
      <Banner />
      <About2 />
      <Quote />
      <CTA />
      <Download />
      <Partners />
    </>
  );
}
