import React from "react";
import { useTranslation } from "react-i18next";

export default function Breadcrumbs({ page }) {
  const { t } = useTranslation();
  return (
    <nav className="rounded-md w-full mb-2">
      <ol className="list-reset flex">
        <li>
          <a href="#" className="text-[#963aee] hover:text-[#963aee]">
            {t("home")}
          </a>
        </li>
        <li>
          <span className="text-gray-500 mx-2">/</span>
        </li>
        <li className="text-gray-500">{t(page)}</li>
      </ol>
    </nav>
  );
}
