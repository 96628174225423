import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import CarouselItem from "../../Components/CarouselItem";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} class="custom-next">
      <i class="fa fa-angle-right"></i>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} class="custom-prev">
      <i class="fa fa-angle-left"></i>
    </div>
  );
}

export default function Carousel() {
  const [data, setData] = useState();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const getData = () => {
    const url =
      "https://buch-kunstregister.de/api/customers/get?pagination-no=12&page=1& id=9585285517364942&password=password1";
    fetch(url, {
      method: "POST",

      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data.data.data);
        console.log(data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container px-4 mx-auto my-8">
      {data?.map((item) => (
        <section className="p-4 my-4 bg-[#eee]">
          <div className="flex items-center justify-between gap-2 border-black pb-2 border-b">
            <div className="flex column items-center">
              <span className="special-vip font-bold member-style-gold p-2 mr-2">
                Gold +
              </span>
              <h1 className="text-[#d4af37] text-xl font-bold">
                ID:{" "}
                <span className="text-[#3f3f3f] text-xl font-bold">
                  {item.id}
                </span>
              </h1>
            </div>
            <div className="text-xl text-[#3f3f3f] font-bold">Werke: 9</div>
            <div className="flex items-center">
              <a className="text-xl text-[#fff] bg-[#d4b039] font-bold">
                Sammlung genauer betrachten{" "}
              </a>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
                class="bi bi-arrow-right-circle"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                ></path>
              </svg>
            </div>
          </div>
          <Slider className="my-4" {...settings}>
            {item.products?.map((item) => (
              <CarouselItem row={item} />
            ))}
          </Slider>
        </section>
      ))}
    </div>
  );
}
