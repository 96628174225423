import { useLayoutEffect, useState } from "react";
import logo from "../src/assets/logo.png";
import "./App.css";
import Header from "./Components/Header";
import Banner from "./Components/Banner";
import Footer from "./Components/Footer";
import CTA from "./Components/CTA";
import About from "./Pages/About";
import Partners from "./Components/Partners";
import About2 from "./Components/About2";
import Download from "./Components/Download";
import Home from "./Pages/Home";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import MessengerCustomerChat from "react-messenger-customer-chat";
import Downloads from "./Pages/Downloads";
import ScrollToTop from "./Helpers/ScrollToTop";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import Contact from "./Pages/Contact";
import Faq from "./Pages/Faq";
import Carousel from "./Pages/Carousel";
import PuffLoader from "react-spinners/PuffLoader";
import DetailPage from "./Pages/DetailPage";
import DetailNew from "./Pages/DetailNew";

function App() {
  const [loading, setLoading] = useState(true);
  useLayoutEffect(() => {
    window.addEventListener("load", (event) => {
      setLoading(false);
    });
  }, []);
  return loading ? (
    <div>
      <div
        className="loading-wrapper-main"
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo} />
        <PuffLoader
          color={"#963aed"}
          loading={loading}
          size={60}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  ) : (
    <div className="scroll-smooth">
      <MessengerCustomerChat
        shouldShowDialog="true"
        pageId="103295529201396"
        appId="444729407829146"
      />
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/download" element={<Downloads />} />
          <Route path="/term_conditions" element={<Terms />} />
          <Route path="/privacy_policy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/task" element={<Carousel />} />
          <Route path="/image" element={<DetailNew />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
