import React from "react";
import { Helmet } from "react-helmet";

export default function Privacy() {
  return (
    <>
      <Helmet>
        <title>Privacy and Policies | Sajilo - Ride Sharing</title>
        <link rel="canonical" href="http://sajiloridesharing.com/about" />
      </Helmet>
      <section className="container  px-4 mx-auto py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-medium leading-10 tracking-tight text-[#0F2137]">
            PRIVACY POLICY
          </h1>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            Last updated: 01 October 2022
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            Welcome to Sajilo-Nepali Ride Sharing. We are committed to
            protecting your privacy. This policy describes our privacy
            practices, the personal information we collect, how we use and share
            it, as well as your choices and rights regarding this information.
            This Privacy Policy applies to our Services wherever we provide
            them, and we will comply with local laws in relation to all
            practices described in this policy. If there is an inconsistency
            between this policy and the local law, we will comply with the local
            law to the extent of the inconsistency. Sajilo - Nepali Ride Sharing
            is provided and controlled by the entity indicated in the Terms of
            Use applicable to your country of residence (“we” or “us”). This
            policy applies to all Sajilo-Nepali Ride Sharing users, including
            users of our website, https://sajilosaathi.com/ (“Website”), any of
            the Sajilo - Nepali Ride Sharing mobile applications (“Apps”), and
            any services we provide through the Website or Apps, as well as when
            you contact us via telephone, email, written correspondence, social
            media, in-person, or any other means of contact (collectively,
            “Services”). This policy does not cover how we handle information
            that we collect about our employees or business associates,
            including our corporate partners, vendors, and subcontractors, but
            does apply to our Drivers. In this policy individuals who utilize
            our Services are referred to as “Users.” Users that request or
            receive transportation are referred to as “Riders” and individuals
            who provide transportation to Riders are referred to as “Drivers.”
            Capitalized terms that are not defined in this policy have the
            meaning given to them in the Terms of Use. If you accept the Terms
            of Use you agree to us dealing with your information in the way
            described in this policy.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            What information do we collect
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            We collect three categories of information about you: information
            you provide, the information we collect automatically, and
            information we obtain from other sources. We do not collect or
            process information about your racial or ethnic origin, political
            opinions, membership of any political association, religious or
            philosophical beliefs, trade-union membership, genetic data,
            biometric data, or data concerning a person’s sex life. Information
            you provide Registration and profile information, such as your name,
            phone number, email, country, city, preferred language, and profile
            picture. Where permitted or required by law, we may collect
            emergency contact numbers. Driver’s information, which may include
            driver’s license data and status, information about the vehicle
            (type, make, model, year of manufacture, color, registration
            certificate data, license plate, vehicle inspection report, vehicle
            photo), ID document data (including driver license, passport, state
            identification numbers), proof of identity, proof of residency,
            physical address, date of birth, taxation identifier number,
            relevant insurance, right to work, driving record, payment or
            banking information, and other documents which may be required by
            applicable regulations. Where permitted or required by law, we may
            conduct identity verification and/or background check. This may
            require collecting and processing such information as your
            photograph, ID document data, social security number, and criminal
            record. This information may be processed by vendors on our behalf.
            User-generated content that you choose to upload through the
            Services, such as comments, ratings, and reviews for other Users.
            Information in correspondence you send to us may include chat
            messages, emails, and recordings of phone calls you make to us.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            Information we collect automatically
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            Location information. We collect Users’ location data to enable
            rides, for user support, for safety and fraud detection purposes,
            and to satisfy legal requirements. We collect location information
            (including GPS coordinates and WiFi data) based on your App
            settings, device permissions, and whether you are using the App as a
            Rider or a Driver (where applicable): - Riders: We collect your
            device’s precise location when the App is running in the foreground
            (App open and on-screen) and when the App is running in the
            background (App open but not on-screen) from the time you request a
            ride until it ends. Riders may use the App without enabling it to
            collect precise location data from their mobile devices by directly
            inputting their pick-up and drop-off addresses or coordinates into
            the App. This may affect the availability of App features. -
            Drivers: We collect your device’s precise location when the App is
            running in the foreground (App open and on-screen) and when the App
            is running in the background (App open but not on-screen) in the
            Driver mode. We may also collect precise locations for a limited
            time after you exit Driver mode in order to detect and investigate
            ride incidents. Transaction information. We collect transaction
            information related to the use of our Services, including the type
            of Services requested or provided, order details, payment
            transaction information, date and time the service was provided,
            amount charged, route and distance traveled, and payment method.
            Usage information. We collect data about your use of our Services.
            This may include such data as access dates and times, App features
            or pages viewed, App or Website crashes and other system activity.
            We may also collect and use for marketing purposes the data related
            to third-party sites you have visited or services you used before
            interacting with our Services. Communications information. We enable
            Users to communicate with each other and with us through the App. To
            facilitate this service, we receive data about the time and date of
            the communications and the content. We may also use this data for
            user support, safety, and security purposes, to resolve disputes
            between users, to improve our Services, and for analytics (such as
            to measure and improve the effectiveness of our website). Device
            information. We collect information about the device you use to
            access the Services, such as your device name, brand and model, user
            agent, IP address, mobile carrier, network type, time zone settings,
            language settings, advertising identifiers, browser type, operating
            system and its version, screen parameters, battery state, and
            installed applications that can be used for authentication purposes.
            We may also collect mobile sensor data, such as speed, direction,
            altitude, acceleration, deceleration, and other technical data.
            Cookies, Analytics, and Third-Party Technologies. We collect
            information through the use of cookies, pixels, tags, and other
            similar tracking technologies (“Cookies”). Cookies are small text
            files that web servers place on your device. They automatically
            collect information, measure and analyze how you use websites and
            applications, and identify which web pages and ads you click on. The
            information we obtain through cookies helps us improve your Sajilo -
            Nepali Ride Sharing experience (for example, by saving your
            preferences) and our marketing programs. Our service providers and
            business partners may use this information to display ads across
            your devices tailored to your preferences and characteristics. You
            can delete cookies and manage cookie settings in your web browser
            settings. Nevertheless, some cookies cannot be disabled since the
            functionality of some of our features depends on them. To learn more
            about these technologies, please see our Cookie Policy.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            Information we obtain from other sources
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            Third-party services. We may receive information about Users from
            our service providers, which include a background check and identity
            verification providers, insurance partners, financial services
            vendors, and marketing providers. If you choose to use a third-party
            communication application (such as WhatsApp) or, sign up using a
            third-party social network or login service (such as Facebook),
            where this functionality is available, we may collect information
            from these services. Please, refer to the third parties' privacy
            policies to find out how they treat your information. State
            authorities. We may receive information about Users from law
            enforcement agencies and other government authorities as part of a
            law enforcement request, during legal proceedings, or as required or
            authorized by law. Other Users or third parties. Sometimes Users or
            others may provide us with information about you, including through
            user support, or in connection with claims or disputes. We will only
            keep and use this information if we could have lawfully collected it
            from you. We will also take such steps (if any) as reasonable in the
            circumstances to let you know we have collected this information.
          </p>
        </div>
        <div className="mb-8">
          <h1 className="text-3xl font-medium leading-10 tracking-tight text-[#0F2137]">
            How we use your information
          </h1>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            We use the personal information we collect:
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            1. To enable you to use our Services - create and maintain your
            account, settings, and preferences; - verify your identity; - enable
            transportation services, or other services, as may be the case; -
            calculate prices and facilitate payments; - enable communications
            between Users; - provide user support; - send non-marketing
            communications about the Service; - maintain accessibility, safety
            and stability of our App and websites, diagnose, prevent and resolve
            technical and service problems; - comply with regulatory
            requirements related to our Services and your use of our Services.
            2. To maintain and enhance the safety and security of our Services
            and Sajilo - Nepali Ride Sharing Users - prevent, detect and combat
            fraud, unsafe or unlawful behavior, and violations of our policies
            and rules; - authenticate Users; - verify that Drivers and their
            vehicles meet safety requirements; - protect personal information,
            rights, and legitimate interests of Sajilo - Nepali Ride Sharing and
            our Users; - investigate and resolve incidents, accidents, and
            insurance claims. 3. To improve existing and develop new features
            and products - perform testing, research, analysis, development, and
            machine learning to improve your Sajilo - Nepali Ride Sharing
            experience; - collect feedback regarding your Sajilo - Nepali Ride
            Sharing experience; - develop new products, features, and services.
            4. To promote Sajilo - Nepali Ride Sharing and our Services - send
            you marketing emails, text messages, push notifications, in-app
            communications, and ads; - provide your personalized experiences,
            content, and advertising on the App, our Website, and on third-party
            platforms; - organize contests, events, and other promotions. For
            information about how to opt-out of certain marketing communications
            (including ads), please see the section titled “Your rights and
            choices.” 5. To comply with legal requirements. We may use your
            personal information to comply with applicable laws, regulations,
            licenses, and industry standards as part of legal proceedings and in
            response to law enforcement requests, and as otherwise authorized or
            required by law. 6. Automated decision-making. We use personal
            information to make automated decisions relating to your use of our
            Services: - connecting Drivers and Riders (or other types of Users,
            as the case may be), based on such factors as availability and
            proximity; - determining User ratings, and deactivating users with
            low ratings; - flagging Users who are identified as having engaged
            in fraud, unsafe or harmful activities; - recommending an average
            price of a ride, based on such factors as distance, location, and
            time. Where required by law, actions based on such processing occur
            only after human review and/or the possibility to appeal.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            User Requirements and Conduct.
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            The services aren’t accessible for use by people younger than 18.
            You may not approve outsiders to utilize your Account, and you may
            not permit people younger than 18 to get transportation or
            coordination administrations from Third Party Providers. You may not
            relegate or generally exchange your Account to some other individual
            or substance. You consent to follow every single pertinent law when
            utilizing the services, and you may just utilize the services for
            legal purposes. You won't, in your utilization of the Services,
            cause disturbance, irritation, burden, or property harm, regardless
            of whether to the Third Party Provider or some other gathering. In
            specific occurrences you might be solicited to give confirmation
            from identity to access or utilize the Services, and you concur that
            you might be denied access to or utilization of the Services in the
            event that you decline to give evidence of personality.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            Promotional Codes.
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            Sajilo offers limited time codes that might be reclaimed for Account
            credit, or different highlights or advantages identified with the
            Services as well as a Third Party Provider's administrations,
            subject to any extra terms that Sajilo sets up on a for every
            special code premise ("Promo Codes"). You concur that Promo Codes:
            (I) must be utilized for the target group and reason, and in a legal
            way; (ii) may not be copied, sold or moved in any way, or made
            accessible to the overall population (regardless of whether
            presented on an open frame or something else), except if explicitly
            allowed by Sajilo; (iii) might be invalidated by Sajilo whenever for
            any reason without risk; (iv) may just be utilized as per the
            explicit terms that Sajilo sets up for such Promo Code; (v) are not
            substantial for money; (vi) may lapse preceding your utilization.
            Sajilo maintains whatever authority is needed to retain or deduct
            credits or different highlights or advantages acquired using Promo
            Codes by you or some other client if Sajilo decides or trusts that
            the utilization or reclamation of the Promo Code was in blunder,
            deceitful, unlawful, or disregarding the pertinent Promo Code terms
            or these Terms & Conditions.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            User Requirements and Conduct.
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            The services aren’t accessible for use by people younger than 18.
            You may not approve outsiders to utilize your Account, and you may
            not permit people younger than 18 to get transportation or
            coordination administrations from Third Party Providers. You may not
            relegate or generally exchange your Account to some other individual
            or substance. You consent to follow every single pertinent law when
            utilizing the services, and you may just utilize the services for
            legal purposes. You won't, in your utilization of the Services,
            cause disturbance, irritation, burden, or property harm, regardless
            of whether to the Third Party Provider or some other gathering. In
            specific occurrences you might be solicited to give confirmation
            from identity to access or utilize the Services, and you concur that
            you might be denied access to or utilization of the Services in the
            event that you decline to give evidence of personality.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            User Provided Content.
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            Sajilo may allow you now and again to submit, transfer, distribute
            or generally make accessible to the Services literary, sound, or
            potentially visual substance and data, including critique and
            criticism identified with the Services, commencement of help
            solicitations, and accommodation of passages for rivalries and
            advancements ("User Content"). Any User Content given by you remains
            your property. In any case, by giving User Content to Sajilo, you
            give an around the world, unending, permanent, transferrable,
            eminence free permit, with the privilege to sublicense, to utilize,
            duplicate, adjust, make subordinate works of, appropriate, openly
            show, freely perform, and generally misuse in any way such User
            Content in all arrangements and dissemination channels currently
            known or in the future contrived (incorporating into association
            with the Services and Sajilo the same old thing and on outsider
            locales and administrations), without further notice to or assent
            from you, and without the prerequisite of installment to you or some
            other individual or element. You speak to and warrant that: (I) you
            either are the sole and select proprietor of all User Content or you
            have all rights, licenses, assets and discharges important to give
            Sajilo the permit to the User Content as put forward above; and (ii)
            neither the User Content nor your accommodation, transferring,
            distributing or generally making accessible of such User Content nor
            Sajilo utilization of the User Content as allowed in this will
            encroach, abuse or damage an outsider's protected innovation or
            exclusive rights, or privileges of exposure or security, or result
            in the infringement of any relevant law or direction. You consent to
            not give User Content that is slanderous, derogatory, contemptuous,
            savage, profane, obscene, unlawful, or generally hostile, as
            controlled by Sajilo in its sole watchfulness, regardless of whether
            such material might be secured by law. Sajilo may, however will not
            be committed to, survey, screen, or evacuate User Content, at Sajilo
            sole prudence and whenever and for any reason, without notice to
            you.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">
            Network Access and Devices.
          </p>
          <p className="mt-2 text-base text-strong text-gray-600">
            You are in charge of getting the information arranged to be
            important to utilize the Services. Your portable system's
            information and informing rates and expenses may apply on the off
            chance that you access or utilize the Services from a remote
            empowered gadget and you will be in charge of such rates and
            charges. You are in charge of getting and refreshing perfect
            equipment or gadgets important to access and utilize the Services
            and Applications and any updates thereto. Sajilo does not ensure
            that the Services, or any segment thereof, will work on a specific
            equipment or gadgets. Also, the Services might be liable to glitches
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">Payment</p>
          <p className="mt-2 text-base text-strong text-gray-600">
            You comprehend that utilization of the Services may result in
            charges to you for the administrations or merchandise you get from a
            Third Party Provider ("Charges''). After you have gotten
            administrations or products acquired through your utilization of the
            Service, Sajilo will encourage your installment of the material
            Charges for the benefit of the Third Party Provider thusly Third
            Party Provider's constrained installment accumulation operator.
            Installment of the Charges in such way will be viewed as equivalent
            to installment made straightforwardly by you to the Third Party
            Provider. Charges will be comprehensive of relevant expenses where
            required by law. Charges paid by you are conclusive and
            non-refundable, except if generally dictated by Sajilo. You hold the
            privilege to ask for lower Charges from a Third Party Provider for
            administrations or products gotten by you from such Third Party
            Provider at the time you get such administrations or merchandise.
            Sajilo will react in like manner to any demand from a Third Party
            Provider to adjust the Charges for a specific administration or
            great. All Charges are expected quickly and installment will be
            encouraged by Sajilo utilizing the favored installment technique
            assigned in your Account, after which Sajilo will send you a receipt
            by email. In the event that your essential Account installment
            strategy is resolved to be terminated, invalid or generally not
            ready to be charged, you concur that Sajilo may, as the Third Party
            Provider's constrained installment accumulation specialist, utilize
            an optional installment technique in your Account, if accessible.
            This installment structure is planned to completely repay the Third
            Party Provider for the administrations or products given. Aside from
            as for cab transportation administrations asked for through the
            Application, Sajilo does not assign any bit of your installment as a
            tip or tip to the Third Party Provider. Any portrayal by Sajilo (on
            Sajilo site, in the Application, or in Sajilo advertising materials)
            such that tipping is "deliberate," "not required," as well as
            "included" in the installments you make for administrations or
            products gave isn't proposed to recommend that Sajilo gives any
            extra sums, past those depicted above, to the Third Party Provider.
          </p>
        </div>
        <div className="mb-8">
          <p className="mt-2 text-lg font-bold text-gray-900">General</p>
          <p className="mt-2 text-base text-strong text-gray-600">
            You may not dole out or move these Terms & Conditions in entire or
            partially without Sajilo endorsement. You give your endorsement to
            Sajilo for it to appoint or move these Terms & Conditions in entire
            or to some extent, including to: (I) a backup or associate; (ii) an
            acquirer of Sajilo value, business or resources; or (iii) a
            successor by merger. On the off chance that any arrangement of these
            Terms & Conditions is held to be unlawful, invalid or unenforceable,
            in entire or to some extent, under any law, such arrangement or part
            thereof will to that degree be regarded not to frame some portion of
            these Terms & Conditions but rather the lawfulness, legitimacy and
            enforceability of alternate arrangements. These Terms & Conditions
            comprise the whole ascension and comprehension of the gatherings as
            for its topic and replaces and overrides all earlier or
            contemporaneous understandings or endeavors with respect to such
            topic.
          </p>
        </div>
      </section>
    </>
  );
}
