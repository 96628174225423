import React from "react";
import riderImg from "../../assets/images/rider.jpeg";
import userImg from "../../assets/images/user.jpeg";
import appstore from "../../assets/images/appstore.png";
import playstore from "../../assets/images/playstore.png";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Downloads() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Download | Sajilo - Ride Sharing</title>
        <link rel="canonical" href="http://sajiloridesharing.com/about" />
      </Helmet>
      <section
        aria-label="Features for building a portfolio"
        className="py-8 lg:py-12"
      >
        <div className="container px-4 mx-auto">
          {/* <div className="">
            <Breadcrumbs page="download_app" />
            <h2 className="mb-2 text-3xl leading-[3.25rem] font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl text-[#0F2137] md:leading-[3.25rem] xl:leading-[3.25rem]">
              Download the app
            </h2>
            <p className="mt-2 text-lg text-gray-600 mb-9 leading-relaxed">
              We'd love to help, Say hello and tell us how we can help.
            </p>
          </div> */}
          <ul
            role="list"
            className="mx-auto mt-8 grid max-w-4xl grid-cols-1 gap-6 text-sm sm:grid-cols-2 md:gap-y-10  lg:grid-cols-2"
          >
            <li className="rounded-2xl shadow-lg wow animate__animated animate__bounceInLeft">
              <img src={userImg} className="w-full block m-auto" />

              <div className="p-8">
                <h3 className="mb-4 p-2 text-3xl font-medium leading-10 tracking-tight text-[#0F2137] ">
                  {t("user")}
                </h3>
                <div className="flex p-2 mt-2">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/np/app/sajilo-nepali-ride-sharing/id1625482126"
                  >
                    {" "}
                    <img className="w-36 pr-2" src={appstore} />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.sajilo.user"
                  >
                    <img className="w-36 " src={playstore} />
                  </a>
                </div>
              </div>
            </li>
            <li className="rounded-2xl shadow-lg wow animate__animated animate__bounceInRight">
              <img src={riderImg} className="w-full block m-auto" />

              <div className="p-8">
                <h3 className="mb-4 p-2 text-3xl font-medium leading-10 tracking-tight text-[#0F2137]">
                  {t("rider")}
                </h3>
                <div className="flex p-2 mt-2">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/np/app/sajilo-rider/id1642037500"
                  >
                    {" "}
                    <img className="w-36 pr-2" src={appstore} />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.sajilo.rider"
                  >
                    <img className="w-36 " src={playstore} />
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
