import React from "react";
import background from "../../assets/images/cta-bg.png";

export default function CTA() {
  return (
    <section className="container-fluid mx-auto">
      <div
        className="relative overflow-hidden bg-no-repeat bg-fixed bg-cover"
        style={{
          backgroundPosition: "50%",
          backgroundImage: `url(${background})`,
          height: "500px",
        }}
      >
        <div
          className="absolute top-0 right-0 bottom-0 left-0 w-full h-full wow animate_animated animate__bounce overflow-hidden bg-fixed"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.40)" }}
        >
          <div className="flex justify-center items-center h-full">
            <div className="text-center text-white px-6 md:px-12">
              <div className="wrapper">
                <div className="video-main">
                  <div className="promo-video">
                    <div className="waves-block">
                      <div className="waves wave-1"></div>
                      <div className="waves wave-2"></div>
                      <div className="waves wave-3"></div>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=yhEqoLXrQyY"
                    className="video video-popup mfp-iframe"
                    data-lity
                  >
                    <i className="fa fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
