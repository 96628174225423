import React from "react";
import aboutImg from "../../assets/images/about.jpg";
import aboutImg2 from "../../assets/images/about2.jpg";
import aboutImg3 from "../../assets/images/lady.jpg";
import cabImage from "../../assets/images/cab.jpg";
import VijaySir from "../../assets/images/vijay-sir.png";
import signature from "../../assets/images/sign.png";
import book from "../../assets/images/icon-book.png";
import reach from "../../assets/images/icon-reach.png";
import pay from "../../assets/images/icon-pay.png";
import arrow from "../../assets/images/arrow.png";
import testImg from "../../assets/images/sajilo-banner.png";
import { useTranslation } from "react-i18next";

export default function Quote() {
  const { t } = useTranslation();
  return (
    <section className="border-t border-gray-200">
      <div className="container py-8 px-4 mx-auto">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full flex items-center px-4 sm:w-1/2 md:w-1/2 lg:w-6/12 xl:w-6/12 wow animate__animated animate__faster animate__slideInUp">
            <img
              className="mt-4 w-full lg:mt-10 p-12 rounded-lg"
              alt="Captain vijay lama with sajilo"
              src={VijaySir}
            />
          </div>
          <div className="w-full px-4 sm:w-1/2 md:w-1/2 lg:w-6/12 xl:w-6/12 wow animate__animated animate__slideInUp">
            <h2
              title={t("title_2")}
              className="mb-4 text-2xl leading-[3.25rem] font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-[#0F2137] md:leading-[3.25rem] xl:leading-[3.25rem]"
            >
              {t("title_2")}
            </h2>
            <p className="mb-4 text-lg text-gray-600">"{t("subtitle_2")}"</p>
            <div className="flex flex-col">
              <p className="text-lg font-bold text-gray-900">
                - {t("vijay_lama")}
              </p>
              {/* <img
                alt="Captaing Vijay Lama Signature"
                src={signature}
                className="w-40"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
