import React from "react";
import AboutPage from "../../Components/AboutPage";
import Breadcrumbs from "../../Components/Breadcrumbs";
import rider from "../../assets/images/rider.jpeg";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import groupPhoto from "../../assets/images/group.jpeg";
import Counter from "../../Components/Counter";

export default function About() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>About | Sajilo - Ride Sharing</title>
        <link rel="canonical" href="http://sajiloridesharing.com/about" />
      </Helmet>
      {/* ====== Contact Section Start */}
      <section className="relative z-10 overflow-hidden bg-white py-8 lg:py-12">
        <div className="container px-4 mx-auto">
          <div className="mb-12 ">
            <Breadcrumbs page="menu_1" />
            <div className="wow animate__animated animate__fadeIn">
              <h2 className="mb-4 text-2xl leading-[3.25rem] font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-[#0F2137] md:leading-[3.25rem] xl:leading-[3.25rem]">
                {t("about_title")}
              </h2>
              {/* <img
              className="rounded-lg shadow-md wow animate__animated animate__fadeIn"
              src={groupPhoto}
            /> */}
              <p className="mt-4 text-lg text-gray-600 mb-9 leading-relaxed">
                {t("about_dec")}
              </p>
              <p className="mt-2 text-lg text-gray-600 mb-9 leading-relaxed">
                {t("about_dec2")}
              </p>
              <div className="mx-auto grid px-0 mt-8 gap-6 md:w-3/4 lg:w-full lg:grid-cols-2">
                <div className="relative relative bg-[#dceaf470]  rounded-lg bg-white shadow-mdflex flex-col w-full p-6">
                  <h1 className=" text-2xl font-medium leading-10 tracking-tight text-[#0F2137]">
                    {t("vision")}
                  </h1>
                  <p className="mb-4 text-lg text-gray-600">
                    {t("vision_dec")}
                  </p>
                </div>
                <div className="relative relative bg-[#dceaf470]  rounded-lg bg-white shadow-mdflex flex-col w-full p-6">
                  <h1 className=" text-2xl font-medium leading-10 tracking-tight text-[#0F2137]">
                    {t("goal")}
                  </h1>
                  <p className="mb-4 text-lg text-gray-600">{t("goal_dec")}</p>
                </div>
              </div>
            </div>
          </div>
          {/* <Counter /> */}
        </div>
      </section>

      {/* ====== Contact Section End */}
    </>
  );
}
