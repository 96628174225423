import React from "react";
import mockup from "../../assets/images/mockup.png";
import alishrai from "../../assets/images/alishrai.png";
import appstore from "../../assets/images/appstore.png";
import playstore from "../../assets/images/playstore.png";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";

export default function Download() {
  const { t } = useTranslation();
  return (
    <section className="border-t border-gray-200 ">
      <div className="container py-8 px-4 mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full flex items-center px-4 sm:w-1/2 md:w-1/2 lg:w-6/12 xl:w-6/12 wow animate__animated animate__slideInUp">
            <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 p-2 text-3xl font-medium leading-10 tracking-tight text-[#0F2137]">
                {t("title_3")}
              </h2>
              <div className="flex">
                <div className="p-2 mr-6 leading-10">
                  <h3 className="text-6xl font-semibold leading-6 text-gray-900">
                    {t("total_downloads")}
                  </h3>
                  <span className="text-[1.4rem] top-[0.5rem] relative tracking-wider">
                    {t("downloads")}
                  </span>
                </div>
                <div className="p-2 leading-10">
                  <h3 className="text-6xl font-semibold leading-6 text-gray-900">
                    {t("total_ratings")}
                  </h3>
                  <span className="text-[1.4rem] top-[0.5rem] relative tracking-wider">
                    {t("rating")}
                  </span>
                </div>
              </div>

              <p className="p-2 text-lg text-gray-600">{t("subtitle_3")}</p>
              <div className="flex p-2 mt-2">
                <a
                  target="_blank"
                  href="https://apps.apple.com/np/app/sajilo-nepali-ride-sharing/id1625482126"
                >
                  {" "}
                  <img className="w-36 pr-2" src={appstore} />
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.sajilo.user"
                >
                  <img className="w-36 " src={playstore} />
                </a>
              </div>
            </div>
          </div>
          <div className="w-full px-4 sm:w-1/2 md:w-1/2 lg:w-6/12 xl:w-6/12 wow animate__animated animate__faster animate__slideInUp">
            <img
              className="mt-4 w-full p-12 lg:mt-10 rounded-lg"
              alt="Prankster Alish Rai with sajilo"
              src={alishrai}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
