import React from "react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import FaqComp from "../../Components/FaqComp";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Faq() {
  const { t } = useTranslation();
  const data = [
    {
      title: "Is this services available 24/7?",
      dec: "Yes, our services is available 24/7 in Nepal. However, the availability of drivers may vary depending on the time of day and location.",
    },
    {
      title: "How do I book a ride?",
      dec: "To book a ride, download the Sajilo - Ride Sharing app, register with your details, and choose your pickup and drop-off locations. The app will then show you the available options and estimated fare for the ride.",
    },
    {
      title: "Is this services available 24/7?",
      dec: "Yes, our services is available 24/7 in Nepal. However, the availability of drivers may vary depending on the time of day and location.",
    },
    {
      title: "How do I book a ride?",
      dec: "To book a ride, download the Sajilo - Ride Sharing app, register with your details, and choose your pickup and drop-off locations. The app will then show you the available options and estimated fare for the ride.",
    },
    {
      title: "Is this services available 24/7?",
      dec: "Yes, our services is available 24/7 in Nepal. However, the availability of drivers may vary depending on the time of day and location.",
    },
    {
      title: "How do I book a ride?",
      dec: "To book a ride, download the Sajilo - Ride Sharing app, register with your details, and choose your pickup and drop-off locations. The app will then show you the available options and estimated fare for the ride.",
    },
    {
      title: "Is this services available 24/7?",
      dec: "Yes, our services is available 24/7 in Nepal. However, the availability of drivers may vary depending on the time of day and location.",
    },
    {
      title: "How do I book a ride?",
      dec: "To book a ride, download the Sajilo - Ride Sharing app, register with your details, and choose your pickup and drop-off locations. The app will then show you the available options and estimated fare for the ride.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>FAQs | Sajilo - Ride Sharing</title>
        <link rel="canonical" href="http://sajiloridesharing.com/about" />
      </Helmet>
      <section
        id="faqs"
        aria-labelledby="faqs-title"
        className="border-t border-gray-200 py-8 sm:py-12"
      >
        <div className="mx-auto  px-4 container">
          <Breadcrumbs page="menu_4" />
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2
              id="faqs-title"
              className="max-w-2xl mb-4 text-3xl leading-[3.25rem] font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl text-[#0F2137] md:leading-[3.25rem] xl:leading-[3.25rem]"
            >
              {t("faq")}
            </h2>
            <p className="mt-2 text-lg text-gray-600">
              {t("faq_dec")}
              {/* */}{" "}
              <Link className="text-gray-900 underline" to="/contact">
                {t("react_out")}
              </Link>
              .
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-4 grid max-w-2xl grid-cols-1 gap-8 sm:mt-8 lg:max-w-none lg:grid-cols-3"
          >
            {data.map((item) => (
              <li>
                <h3 className="text-lg font-semibold leading-6 text-gray-900">
                  {item.title}
                </h3>
                <p className="mt-4 text-sm text-gray-700">{item.dec}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
}
